.timeline {
  position: relative;

  &::before {
    content: '';
    border: var(--spacing-0-5) dashed var(--color-secondary);
    height: 100%;
    left: 1.9rem;
    position: absolute;
    top: 0;
    width: var(--spacing-0-5);
  }

  &:last-child::before {
    display: none;
  }

  .timeline-dot {
    position: relative;

    &::before {
      content: '';
      background-color: var(--color-white);
      border: var(--spacing-0-5) solid var(--color-secondary);
      border-radius: 50%;
      height: 22px;
      left: -28px;
      position: absolute;
      top: 0;
      width: 22px;
    }

    &::after {
      content: '';
      background-color: var(--color-secondary);
      border-radius: 50%;
      height: var(--spacing-4);
      left: -25px;
      position: absolute;
      top: 3px;
      width: var(--spacing-4);
    }
  }
}
