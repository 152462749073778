@import '@quno/patient-journey/styles/breakpoints.scss';

:root {
  --theme-spacing-1: 0.5rem;
  --theme-spacing-2: 1rem;
  --theme-spacing-3: 1.5rem;
  --theme-spacing-4: 2rem;
  --theme-spacing-5: 2.5rem;
  --theme-spacing-6: 3rem;
  --theme-spacing-7: 3.5rem;
  --theme-spacing-8: 4rem;
  --theme-spacing-9: 4.5rem;
  --theme-spacing-10: 5rem;
}

@include media-breakpoints('desktop') {
  :root {
    --theme-spacing-1: 1rem !important;
    --theme-spacing-2: 2rem !important;
    --theme-spacing-3: 3rem !important;
    --theme-spacing-4: 4rem !important;
    --theme-spacing-5: 5rem !important;
    --theme-spacing-6: 6rem !important;
    --theme-spacing-7: 7rem !important;
    --theme-spacing-8: 8rem !important;
    --theme-spacing-9: 9rem !important;
    --theme-spacing-10: 10rem !important;
  }
}
