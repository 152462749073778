@import './variables.css';
@import './variables.scss';
@import 'tailwindcss/base';
@import './fonts.scss';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './typography.scss';
@import './globals.scss';
@import './spacingsGlobal.scss';
@import './fontSizesGlobal.scss';
@import './contentful.scss';

/*! purgecss start ignore */
@import 'react-loading-skeleton/dist/skeleton';
@import 'react-datepicker/dist/react-datepicker';
@import '@uppy/core/dist/style';
@import '@uppy/dashboard/dist/style';
@import '@uppy/webcam/dist/style';
@import 'components/timeline.scss';
@import 'components/carousel-filter.scss';

.no-inline-block-image {
  source + div {
    display: block !important;
  }
}

.carousel-rounded-image {
  source + div > img {
    height: auto !important;
    min-height: auto !important;
  }
}

.react-multi-carousel-dot-list {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin-inline: auto;
  justify-content: center;
  text-align: center;
  list-style-type: none;
}

.uppy-Root {
  z-index: 20 !important;
}

.outerContainer {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.fluid-lazyload .lazyload-wrapper {
  width: 100% !important;
  height: 200px;

  @media (min-width: $screens-xl) {
    height: 250px;
  }
}

body.conversion-bar-visible
  .eapp-whatsapp-chat-root-layout-component
  > div
  > div {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transform: translateY(-71px);
}

.very-high-z-index {
  z-index: 9999999999 !important;
}

.conversion-bar-open {
  transform: translate(0%) !important;
}

form input[type='text'],
form input[type='email'] {
  border: 2px solid var(--color-bluishGrey);
}
form input[type='text']:focus,
form input[type='email']:focus {
  border-color: var(--color-secondary);
}

.carousel-item img {
  max-height: 95vh;
}

[class^='Image_image__'] {
  animation: none !important;
}

.white-link a {
  color: var(--color-white);
  text-decoration: underline;
}

.react-datepicker {
  border-color: var(--color-white);
  display: flex;
  flex-wrap: wrap;
  font-family: var(--fontFamily-adelle);

  &__current-month {
    color: var(--color-navy);
    font-size: var(--text-base-size);
    letter-spacing: 2px;
    margin-bottom: var(--spacing-2);
    text-transform: uppercase;
  }

  &__day {
    color: var(--color-silverLight);
    font-size: var(--text-sm-size);
    margin: 7px;
    width: 30px;

    &:not(.react-datepicker__day--outside-month) {
      height: 30px;
    }

    &--keyboard-selected {
      background: #3591e8;
      border-radius: 3px;
      color: var(--color-white);
    }

    &--selected {
      background: var(--color-secondary) !important;
      border-radius: 3px !important;
      color: var(--textColor-primary) !important;
    }

    &--available:not(.react-datepicker__day--keyboard-selected) {
      background: #ecf7ef;
      border-radius: 3px;
      color: #3dae63;
    }

    &--outside-month:not(.react-datepicker__day--keyboard-selected) {
      background: var(--color-white);
      color: var(--color-silverLight);
    }

    &--today,
    &--non-available:not(.react-datepicker__day--keyboard-selected) {
      background: var(--color-white);
      color: var(--color-silverLight);
      cursor: not-allowed;
      border-radius: 3px;
      pointer-events: none;

      &:hover {
        color: var(--color-silverLight);
        background-color: var(--color-white);
      }
    }
  }

  &__day-name {
    color: #3f4a56;
    font-weight: 700;
    height: 30px;
    margin: 7px;
    margin-bottom: 0;
    text-transform: uppercase;
    width: 30px;
  }

  &__header {
    background: var(--color-white);
    border-color: var(--color-white);
    padding-top: 0;
  }

  &__month-container {
    margin-bottom: 29px;
    width: 100%;

    @media (min-width: $screens-md) {
      width: auto;
    }
  }

  &__navigation--next,
  &__navigation--previous {
    display: none !important;
  }
}

.fixed {
  display: fixed;
}

.overflow-hidden {
  overflow: hidden;
}

/*! purgecss end ignore */
