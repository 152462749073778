@import '@quno/patient-journey/styles/variables.scss';
@import '@quno/patient-journey/styles/functions.scss';

// Typography
$font-primary: 'NettoWeb', 'Adelle Sans', sans-serif;
$font-secondary: 'Adelle Sans', sans-serif;

// Colors
$brand-primary-darkest: #103d4a;
$brand-primary: #155263;
$brand-gray-04: #585a51;
$text-link: #2b6cb0;

body {
  color: $brand-gray-04;
  font-family: $font-secondary;
  font-size: var(--text-lg-size);

  @media (min-width: $screens-md) {
    font-size: var(--text-base-size);
  }
}

h1 {
  font-size: toRem(44px);
  padding: toRem(44px) 0 toRem(21px);
}

h2 {
  font-size: toRem(35px);
  padding: toRem(44px) 0 toRem(21px);

  a {
    text-decoration: none !important;
  }
}

h3 {
  font-size: toRem(28px);
  padding: toRem(21px) 0;
}

h4 {
  font-size: toRem(23px);
  padding: toRem(21px) 0;
}

// TODO: Remove this code because in the website editor, the text link color is already defined
// and it add additional underline to the text link
/* a:not([class*='text-']) {
  color: $text-link;
  text-decoration: underline;
} */

.adelle-bold-section-headline {
  font: {
    family: $font-secondary;
    size: 1.125rem;
    weight: bold;
  }

  text-transform: uppercase;

  @media (min-width: $screens-md) {
    padding-top: 0;
  }
}

// Desktop Overrides
@media (min-width: $screens-md) {
  h1 {
    font-size: toRem(39px);
  }

  h2 {
    font-size: toRem(31px);
  }

  h3 {
    font-size: toRem(25px);
  }

  h4 {
    font-size: toRem(20px);
  }
}

.doctor-clinic-name {
  font-size: toRem(36px);
  line-height: 1.222222222;

  @media (min-width: $screens-lg) {
    font-size: toRem(40px);
    line-height: 0.833333333;
  }
}
