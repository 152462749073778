@import '@quno/patient-journey/styles/breakpoints.scss';

:root {
  --theme-font-h1: 1.625rem;
  --theme-font-h2: 1.5rem;
  --theme-font-h3: 1.375rem;
  --theme-font-h4: 1.25rem;
  --theme-font-h5: 1.125rem;
  --theme-font-h6: 1rem;
  --theme-font-button: 1.125rem;
  --theme-font-p: 1rem;
}

@include media-breakpoints('desktop') {
  :root {
    --theme-font-h1: 3rem !important;
    --theme-font-h2: 2rem !important;
    --theme-font-h3: 1.75rem !important;
    --theme-font-h4: 1.5rem !important;
    --theme-font-h5: 1.375rem !important;
    --theme-font-h6: 1.25rem !important;
    --theme-font-button: 1.25rem !important;
    --theme-font-p: 1.125rem !important;
  }
}
